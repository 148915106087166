import {
  post, get, excel
} from '../utils/http';

// 获取新生信息分页数据
export function getNewStudentPageList(data) {
  return get('yingxin/SY_YingXin_Student_New/GetPageList/', data);
}

// 获取新生导出数据
export function exportNewStudentExcel(data) {
  return excel('yingxin/SY_YingXin_Student_New/ExportExcel/', data);
}

// 获取新生家庭信息分页数据
export function getFamilyPageList(data) {
  return get('yingxin/SY_YingXin_Family/GetPageList/', data);
}

// 获取新生家庭信息导出数据
export function exportFamilyExcel(data) {
  return excel('yingxin/SY_YingXin_Family/ExportExcel/', data);
}

// 获取新生到校计划分页数据
export function getTripPageList(data) {
  return get('yingxin/SY_YingXin_Trip/GetPageList/', data);
}

// 获取新生到校计划导出数据
export function exportTripExcel(data) {
  return excel('yingxin/SY_YingXin_Trip/ExportExcel/', data);
}

// 获取缴费信息分页数据
export function getSFXXPageList(data) {
  return get('yingxin/SY_YingXin_SFXX/GetPageList/', data);
}

// 获取缴费信息导出数据
export function exportSFXXExcel(data) {
  return excel('yingxin/SY_YingXin_SFXX/ExportExcel/', data);
}

// 获取贷款信息分页数据
export function getDKXXPageList(data) {
  return get('yingxin/SY_YingXin_ZXDK/GetPageList/', data);
}

// 获取贷款信息分页数据
export function GetDKXXFormData(data) {
  return get('yingxin/SY_YingXin_ZXDK/GetFormData/', data);
}

// 获取贷款信息导出数据
export function exportZXDKExcel(data) {
  return excel('yingxin/SY_YingXin_ZXDK/ExportExcel/', data);
}

// 获取贷款信息导出数据
export function exportLSZXDKExcel(data) {
  return excel('yingxin/SY_YingXin_ZXDK/LSExportExcel/', data);
}

// 获取贷款信息分页数据
export function saveDKSPInfo(data) {
  return post('yingxin/SY_YingXin_ZXDK/Save/', data);
}

// 获取贷款信息分页数据
export function getLSDKXXPageList(data) {
  return get('yingxin/SY_YingXin_ZXDK/GetLSPageList/', data);
}

// 获取组织信息分页数据
export function getAskPageList(data) {
  return get('yingxin/SY_YingXin_Ask/GetPageList/', data);
}

// 获取组织信息导出数据
export function exportAskExcel(data) {
  return excel('yingxin/SY_YingXin_Ask/ExportExcel/', data);
}
